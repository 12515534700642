/*
* 업무구분: 신탁>고객/상품>고객정보조회>거래내역조회
* 화 면 명: MSPTS110M
* 화면설명: [2515]거래내역조회
* 작 성 일: 2023.03.28
* 작 성 자: 지재호 
* 파일명 : 45100.xfdl
*/
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">

    <!-- header start -->
    <ts-header
      ref="tsHeader"
      :propObj="pHeaderObj"
    ></ts-header>
    <!-- header end -->

    <!-- .fts-main -->
    <div class="fts-main">

      <!-- 계좌번호 콤보화면 -->
      <msp-ts-95090D
        ref="search"
        @ts-alert-popup="fn_AlertPopup"
        @ts-popup-callback="fn_Popup_CallBack"      
        @ts-fn-init="fn_init"
        @ts-fn-search-list="fn_searchList"
        :dSearchObj="searchObj"
      ></msp-ts-95090D>

      <ur-box-container direction="column" alignV="start">                 
        <div class="wrap-table-title row">
          <h2 class="table-title"> 기본정보 </h2>
        </div>

        <!-- <div class="right">
          <div class="wrap-button row">
            <mo-button @click="parentFuncCall"> 조회 </mo-button>
          </div>
          searchObj : {{searchObj}}<br>
        </div> -->
            
        <div class="wrap-table mb-5">
          <table class="table col-type w50p col1419">
            <tbody>
              <tr class="wrap-input">
                <th>
                  <span>신탁계약일</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker v-model="cal_contDate" :bottom="false" class="input-long" disabled />
                  </div>
                </td>
                <th>
                  <span>신탁만기일</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-date-picker v-model="cal_mtrtDate" :bottom="false" class="input-long" disabled />
                  </div>
                </td>
                <th class="bl-ddd">
                  <span>통화구분</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-radio-wrapper class="row" disabled
                      :items="ds_curC" 
                      v-model="rdo_curC" 
                      ref="rdo_curC"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="wrap-table">
          <table class="table row-type">
            <thead>
              <tr>
                <th> 거래일자 </th>
                <th> 거래번호 </th>
                <th> 적요 </th>
                <th> 거래금액 </th>
                <th> 
                  <p class="tdRow">이체</p>
                  <p class="tdRow">대체</p>
                </th>
                <th> 상대계좌번호 </th>
                <th> 신탁원본 </th>
                <th> 통화코드 </th>
                <th> 처리점 </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in ds_list" :key="i">
                <td> {{fn_dateReplace(row.trDate)}} </td>
                <td> {{row.trno}} </td>
                <td> {{row.rmkCNm}} </td>
                <td class="aR"> {{$bizUtil.numberWithCommas(row.trAmt)}} </td>
                <td class="aR"> 
                  <p class="tdRow">{{$bizUtil.numberWithCommas(row.ftrnAmt)}}</p>
                  <p class="tdRow">{{$bizUtil.numberWithCommas(row.brigIamtAmt)}}</p>
                </td>
                <td class="aL"> {{row.objInfo}} <br /></td>
                <td class="aR"> {{$bizUtil.numberWithCommas(row.trstBalAggt)}}<br /></td>
                <td> {{row.curC}} </td>
                <td> {{row.brIfnm}} </td>
              </tr>
            </tbody>
          </table> 
        </div>
		  </ur-box-container>
    </div>
    <!-- //.fts-main -->

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>
    
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * DataSet 영역	                                                                    *
   ***********************************************************************************/
  const defaultDsList = {  
    tacno: '',
    bnkbSeq: '',
    accSeq: '',
    trDate: '',
    trno: '',
    gdNm: '',
    contDate: '',
    mtrtDate: '',
    bnkbPrtYn: '',
    rmkCNm: '',
    bkno: '',
    prinBalAggt: '',
    trstBalAggt: '',
    brIfnm: '',
    cashAmt: '',
    trAmt: '',
    ftrnAmt: '',
    contTrnsAmt: '',
    brigIamtAmt: '',
    rmkC: '',
    slipSeq: '',
    objOrgCNm: '',
    objAcno: '',
    objAcnm: '',
    curC: '',
    objInfo: '',
    fullAcct: '',
    acctNo2Yn: '',
  }

  const defaultDsSearch = { 
    tacno: '',
    bnkbSeq: '',
    accSeq: '',
    fromTrDate: '',
    toTrDate: '',
    iqryCls: '',
  }

  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
  import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
  import TSComDate from '~/src/ui/ts/comm/TSComDate'
  import TSHeader from '~/src/ui/ts/comm/TSHeader'          // header 영역 (공통)
  import MSPTS95090D from '~/src/ui/ts/MSPTS95090D' // 계좌조회
  // 팝업
  import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
  import TSInfoUtil    from '~/src/ui/ts/comm/TSInfoUtil'
  // Vue.component(MSPTS95090D.name, MSPTS95090D)

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPTS110M",
    screenId: "MSPTS110M",
    components: {
      'ts-header': TSHeader,
      'msp-ts-95090D': MSPTS95090D,
      'ts-alert-popup': TSAlertPopup,
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {

    },
    mounted() {
      this.fn_afterFormOnload()
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        // 공통 객체
        pHeaderObj: {          
          title: '거래내역조회',
          step: 1,
        },
        pAlertPopupObj: {}, // 팝업 객체

        // 검색 컴포넌트 객체
        searchObj: {cal_from: '', cal_to: '', m_id:'MSPTS110M'},
        rdo_curC: 'kr', // 원화
        cal_contDate: '', // 계약일자
        cal_mtrtDate: '', // 만기일자
        acno:'' , 
        valueSlt1: '1',
        valueDate3: '',
        valueDate4: '',
        valueRadio1: '1',
        popYn: '',
        from07250: false,
        commonInfo: [
          { value1: '2023-01-31', value2: '2', value3: '이체수수료입금', value4: '50,000,000', value5: '50,000,000', value6: '0', value7: '최성민(우리)', value8: '264****-428999', value9: '50,000,000', value10: 'KRW', value11: '신탁창구', value12:'N' },
          { value1: '2023-01-31', value2: '1', value3: '이체수수료입금', value4: '50,000,000', value5: '50,000,000', value6: '0', value7: '최성민(우리)', value8: '264****-428999', value9: '50,000,000', value10: 'KRW', value11: '신탁창구', value12:'N' },
        ],

        ds_search: Object.assign({}, defaultDsSearch),
        ds_list: [], //Object.assign({}, defaultDsList)

        eaiCommObj: TSCommUtil.gfn_eaiCommObj(), // Contents Model 
        
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      itemSlt1() {
        let rtn = [];
        rtn.push({value: '1', text: '리치플랜외화채권(U)'});
        rtn.push({value: '2', text: '리치플랜외화채권2'});
        return rtn;
      },
      ds_curC() {
        let rtn = [];
        rtn.push({value: 'kr', text: '원화'});
        rtn.push({value: 'us', text: '외화'});
        return rtn;
      },      

      
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
       /********************************************************************************************************************
        * Function   : fn_dateReplace
        * Parameter  : 생년월일 replace
        ********************************************************************************************************************/
      fn_dateReplace (pDate) {
        return TSCommUtil.gfn_dateReplace(1, pDate)
      },

      /***************************************************************************************
        벨리데이션 error css, 메시지 처리 함수 (기본형)
        param : 배열 오브젝트
        return : boolean / true : 정상 , flase : 체킹됨

        내부 함수 localValid 리턴값을 리턴한다
        단건라인 호출시 : this.fn_validate( [{ mValue:this.g_cmb_modifyGb , title: '정정구분', ref:'cmb_modifyGb' }] )
      *****************************************************************************************/
      fn_validate ( pParam ) {
        
        // console.log(window.vue.getStore('userInfo').getters.getUserInfo) // getStore sesseion 정보
        
        let pValidArry = [];
        if ( ! pParam.length > 0 ) { // 값이 없을 경우 기본 디폴트값 설정 또는 무시
         
        } else {
          pValidArry = pParam
        }
        
        // 실행 및 리턴
        const lv_vm = this
        let rtnBool = true
        let chekcedCnt = 0
        for ( let idx in pValidArry ) {
          // console.log(this.validCheck[arguments[i]])
          let modelObj = pValidArry[idx]
          if(TSCommUtil.gfn_isNull(modelObj.mValue)){
            try {
              const lv_el = lv_vm.$refs[modelObj.ref].$el
              // const thidDom = lv_vm.$refs[modelObj.ref].$el
              if ( ! lv_el.classList.contains('error') ) {  // class에 erorr가 없을시만 추가
                lv_el.classList.add('error')
              }
              if ( chekcedCnt == 0 ) {  // 스낵바 메시지는와 포커스는 하나만 출력
                TSCommUtil.gfn_validate(lv_vm, modelObj.title+"은(는) 필수입력 항목입니다.")
                lv_el.focus()
              }
            } catch (error) {
              console.log(modelObj.ref + '실패')
              continue
            }
            rtnBool = false
            chekcedCnt++
          }
        }
        return rtnBool
      },

      /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) : 0: 계좌조회 
       ******************************************************************************/
      fn_Popup_CallBack(type, pData) {
        console.log(pData)
        let t_popupObj = {}

        switch (type) {
          case 0:
            console.log('계좌조회 팝업')
            break
        }
      },

      /******************************************************************************
       * Function명 : fn_Popup_CallBack_Code
       * 설명       : popup 컴포넌트에서 callback
       ******************************************************************************/
      fn_Popup_CallBack_Code(type, pData) {
        this.ds_detail.insrJobC     = pData.vjob_cd // 보험코드
        this.ds_detailBfc.insrJobC  = pData.vjob_cd // 보험코드
        this.fn_validErrRemoveRef(this.$refs['edt_insrJobC']) // css error 검출 삭제
      },

      parentFuncCall(){
        // this.$refs.search.btn_popupTPUP95350_onclick()
        // this.searchObj.main = 'parentFuncCall' 
        
        
        // this.searchObj.time = Math.floor(Math.random() * 1000)
        // this.searchObj.time = Math.floor(Math.random() * 1000)
        // console.log('실행 : ' + this.searchObj.time)
        // const lv_vm = this
        // this.$refs.search.sendObj(lv_vm, this.searchObj)
        
      },
      test(){
        alert('test')
      },

      parentFunc(){
        alert('부모창')
      },
      /***********************************************************************************
          화면 공통 초기화 
      **********************************************************************************/
      form_onload ()
      {
        this.gfn_setInitForm(obj,e);
        this.alert('거래내역조회')
      },


      /***************************************************************************************
          실질적인 화면 초기화 
      *****************************************************************************************/
      fn_afterFormOnload ()
      {
        let busyDate = TSCommUtil.fn_strToday() // TODO : 이거로 해야함 =>this.gfn_getBusyDate() 	//
        // this.$refs._setPassbookUseYn("Y"); // 통발기 사용여부 => 사용안함
        // this.$refs.cal_from =  TSCommUtil.fn_strToday() // this.gfn_getBusyDate() 
        
        // this.$refs.search.$data.cal_from = busyDate 
        // this.$refs.search.$data.cal_to = busyDate 
        // 아래로 변경
        this.searchObj.cal_from = TSCommUtil.gfn_dateReplace(1, busyDate)
        this.searchObj.cal_to = TSCommUtil.gfn_dateReplace(1, busyDate)

        if ( this.$route.params.paramString !== undefined ) {
          const parsObj = JSON.parse( this.$route.params.paramString )
          this.$refs.search._setValue( parsObj.tacno + parsObj.bnkbSeq , parsObj.accSeq);
          
          if ( parsObj.from == "TFBZ07250" ) {
            this.$refs.search._setValue(TSComDate.gfn_minusMonth(busyDate, 3));
            this.from07250 = true
          }

          // 화면에서 값이 넘어온 경우에만 
          // 기존 as-is 에서는 팝업으로 이 페이지를 호출할때 사용되나 현 프로젝트에선 사용 페이지 없음
          // if ( !TSCommUtil.gfn_isNull(parsObj.lcTacno) ) {
          //   this.popYn = "Y";
          //   this.$refs.search._setValue( parsObj.lcTacno + parsObj.lcBnkbSeq, parsObj.lcAccSeq);        
            
          //   // 여기부터 해야함
          //   this.btn_searchList01.set_visible(true);
          //   if ( !this.gfn_isNull(parsObj.lcTrDate)) {
          //     this.Div02.cal_from.set_value(parsObj.lcTrDate);
          //     this.Div02.cal_to.set_value(parsObj.lcTrDate);
              
          //   }
            
          //   this.Div03.set_visible(false);
          //   this.$refs.search.set_enable(false);
          // }
        }
        this.$refs.search.$refs.Edt_acno.focus();
        this.popYn = "N";

        // this.$refs.search.sendObj(this, this.searchObj)
      },

      /***************************************************************************************
          계좌정보 조회 후 
      *****************************************************************************************/
      acctCallBackFunc ( result )
      {
        // 팝업으로 호출될시 조회됨. 현재 이페이지 팝업여부는 없음
        if ( this.popYn == "Y" ) {
          this.fn_searchList01();
        }
        
        if ( this.from07250 ) {
          this.fn_searchList();
          
          this.from07250 = false;
        }
      },

      /***************************************************************************************
          초기화 
      *****************************************************************************************/
      fn_init ()
      {
        this.$refs.search._fn_dataInit();// 계좌컨트롤 항목 초기화 
        this.cal_contDate = '' 
        this.cal_mtrtDate = ''
        this.rdo_curC = 'kr'
        Object.assign(this.ds_search, defaultDsSearch)
        this.ds_list = []

        // 유저 정보 세팅
        let vm = this
        vm.$bizUtil.tsUtils.selBasInfo(vm, false).then(function (response) {
          vm.lv_basInfo = response.body
          vm.busyDate = vm.lv_basInfo.busyDate // this.gfn_getBusyDate()
          vm.searchObj.cal_from = vm.lv_basInfo.busyDate // this.gfn_getBusyDate()
          vm.searchObj.cal_to = vm.lv_basInfo.busyDate // this.gfn_getBusyDate()
        }).catch(function (error) {
          window.vue.error(error)
        })

        this.fn_rmAllErrCss() // error class 전체 삭제
      },

      /***********************************************************************************
          조회
      **********************************************************************************/
      fn_searchList ()
      {
        // 필수입력값 체크 
        if ( TSCommUtil.gfn_isNull(this.$refs.search._getTacnoValue()) ) { // search 컴포넌트에 조회된 내역이 없는경우
          this.fn_addValidErr(this.$refs.search.$refs.Edt_acno.$el)
          TSCommUtil.gfn_validate(this, '계좌번호는 필수입력 항목입니다.')
          return
        }	

        if ( TSCommUtil.gfn_isNull( this.$refs.search.$refs.cal_from.value ) ) { // search 컴포넌트에 조회된 내역이 없는경우
          this.fn_addValidErr(this.$refs.search.$refs.cal_from.$el)
          TSCommUtil.gfn_validate(this, '시작일은 필수입력 항목입니다.')
          return
        }	

        if ( TSCommUtil.gfn_isNull( this.$refs.search.$refs.cal_to.value ) ) { // search 컴포넌트에 조회된 내역이 없는경우
          this.fn_addValidErr(this.$refs.search.$refs.cal_to.$el)
          TSCommUtil.gfn_validate(this, '종료일은 필수입력 항목입니다.')
          return
        }	

        // serach 컴포넌트 기간 체크
        let calFromDate = Number ( this.$refs.search.$refs.cal_from.value.replaceAll("-","") )
        let calToDate = Number ( this.$refs.search.$refs.cal_to.value.replaceAll("-","") )
        if ( calFromDate > calToDate) {
          this.fn_addValidErr(this.$refs.search.$refs.cal_from.$el)
          TSCommUtil.gfn_validate(this, '시작일자가 종료일자보다 큽니다.')
          return false;
        }
        

        Object.assign(this.ds_search, defaultDsSearch)
        this.ds_search.tacno = this.$refs.search._getTacnoValue()
        this.ds_search.bnkbSeq = this.$refs.search._getBnkbSeqValue()
        this.ds_search.accSeq = this.$refs.search._getAccSeqValue()
        this.ds_search.fromTrDate = String ( calFromDate ) // this.searchObj.cal_from 으로 해도 무방
        this.ds_search.toTrDate = String ( calToDate ) // this.searchObj.cal_to 로 해도 무방
        // this.ds_search.iqryCls = '1'

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600173_S'
        this.eaiCommObj.params = Object.assign({},this.ds_search)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)

        // var sSvcID        = "selectTFIO45100List";
        // var sURL          = "svc::selectTFIO45100List.do";
        // var sInDatasets   = "tfio45100=ds_search:";
        // var sOutDatasets  = "ds_list=tfio45100List";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBack";
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
      },

      /************************************************************************************************
       * 계좌조회 정상응답 후 처리 
       ************************************************************************************************/
      fn_callBack ( res )
      {
        const resData = Object.assign({}, res.data)
        if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
          this.fn_AlertPopup(0,{content : resData.errorMsg}) 
          return false
        }

        if ( TSCommUtil.gfn_length(resData.treadeHistory) > 0 )
          this.ds_list = resData.treadeHistory
        else 
          this.ds_list = []
        
        
        // TODO : 임시 테스트값
        // this.ds_list = [
        //   { value1: '2023-01-31', value2: '2', value3: '이체수수료입금', value4: '50,000,000', value5: '50,000,000', value6: '0', value7: '최성민(우리)', value8: '264****-428999', value9: '50,000,000', value10: 'KRW', value11: '신탁창구', value12:'N' },
        //   { value1: '2023-01-31', value2: '1', value3: '이체수수료입금', value4: '50,000,000', value5: '50,000,000', value6: '0', value7: '최성민(우리)', value8: '264****-428999', value9: '50,000,000', value10: 'KRW', value11: '신탁창구', value12:'N' },
        // ]

        let vCurC = this.$refs.search._getCurCValue(); // 통화코드 
        let cal_contDate = this.$refs.search._getContDateValue() // 계약일자
        let cal_mtrtDate = this.$refs.search._getMtrtDateValue() // 만기일자
        // this.Div01.cal_contDate.set_value(this.$refs.search._getContDateValue()); // 계약일자 
        // this.Div01.cal_mtrtDate.set_value(this.$refs.search._getMtrtDateValue()); // 만기일자 

        this.cal_contDate = TSCommUtil.gfn_dateReplace(1, cal_contDate )
        this.cal_mtrtDate = TSCommUtil.gfn_dateReplace(1, cal_mtrtDate )
        
        // 원화의 경우 통화구분을 변경할수 없다.
        if ( vCurC == "KRW" ){
          // this.Div01.rdo_curC.set_enable(false);
          this.rdo_curC = 'kr'
        }
        else {
          // this.Div01.rdo_curC.set_enable(true);
          this.rdo_curC = 'us'
        }
        
      },

      /***********************************************************************************
          엑셀다운로드 
      **********************************************************************************/
      fn_excelDown ()
      {
        this.gfn_exportExcel(this.grd_list00, "TFIO45100", "계좌내역조회");
      },


      /************************************************************************************************
      - 그리드의 onheadclick 이벤트에 this.gfn_gridSort함수를 선언하여 사용한다.
      - 컬럼을 지정하여 정렬처리할 경우, 아래와 같이 분기처리한다.
        예) if(e.col == 3 || e.col == 5 || e.col > 8)
      - 데이터가 숫자인 경우, 데이터셋의 해당컬럼 타입이 number계열이여야 정상적으로 sort된다.
      *************************************************************************************************/
      grd_list_onheadclick ()
      {	
        this.gfn_gridSort(obj,e);
      },

      /***********************************************************************************
          보고서출력 
      **********************************************************************************/
      fn_print ()
      {		
        // 필수입력값 체크 
        if ( this.gfn_isNull(this.Div02.div_acno._getTacnoValue()) ) {
          this.alert("계좌번호를 입력하시오.");
          this.Div02.div_acno.setFocus();
          return false;
        }	
        if ( !this.gfn_isValidateCompList( this.Div02.cal_from, this.Div02.cal_to) ) {
          
          return false;
        }
        
        if (this.Div02.cal_from.value > this.Div02.cal_to.value) {
          this.alert("시작일자가 종료일자보다 큽니다.");
          this.Div02.cal_from.setFocus();
          return false;
        }
        
        this.ds_search.clearData();
        
        var nRow = this.ds_search.addRow();
        
        this.ds_search.setColumn(nRow, "tacno", this.Div02.div_acno._getTacnoValue());
        this.ds_search.setColumn(nRow, "bnkbSeq", this.Div02.div_acno._getBnkbSeqValue());
        this.ds_search.setColumn(nRow, "accSeq", this.Div02.div_acno._getAccSeqValue());
        this.ds_search.setColumn(nRow, "fromTrDate", this.Div02.cal_from.value);
        this.ds_search.setColumn(nRow, "toTrDate", this.Div02.cal_to.value);
        this.ds_search.setColumn(nRow, "iqryCls", "");
          
        var reportMrdNm = "/cronix/fiduciary/ioamt/TFIO45100.mrd"; // 보고서 파일 경로
        var reportUrl    = "reportTFIO45100List.do"; // 보고서 호출 서비스url
        var reportParam  = this.ds_search;
        
        //보고서 출력 함수
        this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam);
            
      },



      /***************************************************************************************
          화면 종료시 장비 해제 
      *****************************************************************************************/
      TFIO45100_onbeforeclose ()
      {
        this.Div02.div_acno.pbprKill(); // 통장프린트기 연결종료 
      },

      /***************************************************************************************
          화면 종료시 장비 해제 
      *****************************************************************************************/
      grd_list_oncelldblclick ()
      {
        if ( this.popYn == "Y" ) {
          result = new Object();
          result.trno = this.ds_list.getColumn( e.row, "trno" );
          result.trDate = this.ds_list.getColumn( e.row, "trDate" );
          this.gfn_popupClose(result);
        }
      },

      /***************************************************************************************
          팝업용조회 
      *****************************************************************************************/
      btn_searchList01_onclick ()
      {
        this.fn_searchList01();
      },

      /***************************************************************************************
          팝업용조회 
          현재 이페이지 팝업 여부는 없음
      *****************************************************************************************/
      fn_searchList01 ()
      {
        console.log('팝업여부는 없음')
        return 
        Object.assign(this.ds_search, defaultDsSearch)

        this.ds_search.tacno = this.$refs.search._getTacnoValue()
        this.ds_search.bnkbSeq = this.$refs.search._getBnkbSeqValue()
        this.ds_search.accSeq = this.$refs.search._getAccSeqValue()
        this.ds_search.fromTrDate = this.searchObj.cal_from // this.$refs.search.$refs.cal_from 으로 해도 무방
        this.ds_search.toTrDate = this.searchObj.cal_to // this.$refs.search.$refs.cal_to 로 해도 무방
        this.ds_search.iqryCls = "2"
        
        var sSvcID        = "selectTFIO45100List";
        var sURL          = "svc::selectTFIO45100List.do";
        var sInDatasets   = "tfio45100=ds_search:";
        var sOutDatasets  = "ds_list=tfio45100List";
        var sArgument     = "";
        var sCallbackFunc = "fn_callBack";
        
        this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
      },

      grd_list_oncellclick ()
      {
        // 상대계좌정보 클릭시 
        if ( e.col == 6 ) {
          if ( !this.gfn_isNull(this.ds_list.getColumn(e.row, "objInfo"))) {
            this.gfn_getMessage( "alert", this.ds_list.getColumn(e.row, "objInfo"));
          }
        }
      },

      /******************************************************************************
       * Function명 : fn_AlertPopup
       * 설명       : alert popup 호출(공통)
      ******************************************************************************/      
      fn_AlertPopup (type, pPopupObj) {
        switch (type) {
          case 0:
            if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
              TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
            }
            this.$refs.alertPopup.fn_Open()     
          break
        }
      },

      /******************************************************************************
       * Function명 : fn_addValidErr
       * 설명       : 에러 클래스 추가
      ******************************************************************************/      
      fn_addValidErr (lv_el) {
        if ( ! lv_el.classList.contains('error') ) {  // class에 erorr가 없을시만 추가
          lv_el.classList.add('error')
        }
        lv_el.focus()
      },
     
       /******************************************************************************
      * Function명 : fn_rmAllErrCss
      * 설명       : 해당 컴포넌트 error css 삭제
      ******************************************************************************/
      fn_rmAllErrCss () {
        for ( let idx in this.$refs ) {
          if ( this.$refs[idx] != undefined ) {
            this.$refs[idx].$el.classList.remove("error")
          }
        }
      },

    },
    /***********************************************************************************
     * watch 함수 정의 영역                                                         *
     ***********************************************************************************/
    watch: {
      
    }
  }
</script>

<style lang="scss">
</style>

<style scoped>
</style>
